<template>
  <v-main>
    <v-container>
      <h1 class="display-1">
        <span>Privacy</span>
      </h1>
      <div class="mt-7">
        <p>
          The information you enter into Peeps is for your use in managing your homestead — not
          unlike using a spreadsheet or an accounting system to help you organize your information.
          The designers of Peeps have figured out how to best manage a home and have incorporated
          what they have learned and shared their experiences in the Peeps app.
        </p>
        <p>
          That information is stored on secure servers in the Google Cloud Platform. Encryption
          brings an even higher level of security and privacy. As the data you enter moves between
          your device and the Google data centers, it is protected by security technology like HTTPS
          and Transport Layer Security.
        </p>
        <p>
          Your account is password protected, and only you have access to that password, as it is
          stored on an encrypted Google server that prevents access to your password by anyone. In
          fact, if you forget your password, you will have to reset it to a new one, as Peeps can’t
          help you retrieve it.
        </p>
        <p>
          That said, much of the data on your Peeps account is public record. The Town website,
          accessible to everyone, allows lookup of your property by your name or address and viewing
          of historical and assessor and related valuation information.
          <span v-if="showIsInTown">
            Over 130 pieces of information on your property can be found here:
            <a target="_blank" href="https://www.mapsonline.net/truroma/">
              https://www.mapsonline.net/truroma/
            </a>
          </span>
        </p>
        <p>
          Water test and septic system information is generally not publicly available, so
          safeguarding your password is important to prevent unauthorized access.
        </p>
        <p>
          Peeps will only communicate information to the account owner, for the purpose of making
          recommendations, providing you with trends, and reminding you about important maintenance
          due dates. In addition, that data may be used as part of a statistical data analysis.
          Statistical analysis is helpful to everyone by highlighting broader trends that can be
          insightful and helpful to all — not unlike many polls. But in that case, individual data
          will not be disclosed, only aggregated statistics.
        </p>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import parcel from '@/mixins/parcel';
export default {
  mixins: [parcel],
  data: () => ({
    showIsInTown: false
  }),
  metaInfo() {
    return {
      title: 'Privacy'
    };
  },
  mounted() {
    this.loadIsInTown();
  },
  methods: {
    async loadIsInTown() {
      let res = await this.isInTown('Truro');
      console.log(res);
      this.showIsInTown = res;
    }
  }
};
</script>

<style scoped></style>
